import React, {lazy, Suspense, useEffect, useState} from "react";
import { BrowserRouter, Routes, Route, HashRouter as Router } from "react-router-dom";
import './App.css';
const Layout = lazy(() => import("./pages/Layout"));
const Index = lazy(() => import("./pages/Index"));
const IndexView = lazy(() => import("./pages/IndexView"));
const StockView = lazy(() => import("./pages/StockView"));
const NoPage = lazy(() => import("./pages/NoPage"));
const TransactionsAccountDetail = lazy(() => import("./pages/Transactions/AccountDetail"));
const TransactionsCategoryDetail = lazy(() => import("./pages/Transactions/CategoryDetail"));
const Profile = lazy(() => import("./pages/Profile"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const Terms = lazy(() => import("./pages/Terms"));
const DataUse = lazy(() => import("./pages/DataUse"));
const Disclaimer = lazy(() => import("./pages/Disclaimer"));
const Goals = lazy(() => import("./pages/Budget/Goals"));
const Navbar = lazy(() => import("./components/Header/NavBar/Navbar"));
const Login = lazy(() => import("./components/LoginRegister/Login"));
const Register = lazy(() => import("./components/LoginRegister/Register"));
const Footer = lazy(() => import("./components/Footer/Footer"));
const Header = lazy(() => import("./pages/Header"));
const TabAccounts = lazy(() => import("./pages/Dashboard/TabAccounts"));
const TabCategories = lazy(() => import("./pages/Dashboard/TabCategories"));
const TabTransactions = lazy(() => import("./pages/Dashboard/TabTransactions"));
const TabBudget = lazy(() => import("./pages/Dashboard/TabBudget"));
const TabDashboard = lazy(() => import("./pages/Dashboard/TabDashboard"));
const InvestmentAccountsHoldingsView = lazy(() => import("./pages/Investments/InvestmentAccountsHoldingsView"));
const InvestmentHoldingsView = lazy(() => import("./pages/Investments/InvestmentHoldingsView"));
const BudgetDetail = lazy(() => import("./pages/Budget/BudgetDetail"));

function App() {
  const [error, setGlobalError] = useState("");
  const [user, setUser] = useState({});
  const [, setUserIsLoaded] = useState(false);
  const [, setUserError] = useState(null);

  const [watchlistItems, setWatchlistItems] = useState([]);
  const [watchlistError, setWatchlistError] = useState(null);
  const [watchlistIsLoaded, setWatchlistLoaded] = useState(false);

  const [moreItemsLoaded, setMoreItemsLoaded] = useState(false);
  const [moreItemsError, setMoreItemsError] = useState(null);
  const [moreItems, setMoreItems] = useState([]);

  const host = "https://pfinance.ca"
  // const host = "http://localhost:4000"

  const getUser = (url, setIsLoaded, setUser, setError) => {
    fetch(url,{
        method: "get",
        credentials: 'include',
    })
    .then(res => res.json())
    .then(
        (result) => {
          setIsLoaded(true);
          setUser(result);
          getHistory(host + "/backend/history", setMoreItemsLoaded, setMoreItems, setMoreItemsError);
          getWatchlist(host + "/backend/watchlist", setWatchlistLoaded, setWatchlistItems, setWatchlistError);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
    )
  }
  
  const getHistory = (url, setIsLoaded, setItems, setError) => {
    fetch(url,{
        method: "get",
        credentials: 'include',
    })
    .then(res => res.json())
    .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
    )
  }

  
const getWatchlist = (url, setIsLoaded, setItems, setError) => {
  fetch(url,{
      method: "get",
      credentials: 'include',
  })
  .then(res => res.json())
  .then(
      (result) => {
          setIsLoaded(true);
          setItems(result);
      },
      (error) => {
          setIsLoaded(true);
          setError(error);
      }
  )
}

  useEffect(() => {
    const url = host + "/backend/user";
    getUser(url, setUserIsLoaded, setUser, setUserError);
  }, [])

  const clearGlobalError = (e) => {
    e.preventDefault();
    setGlobalError("");
    return false;
  }







  return (
    <>
        <Navbar getUser={() => {getUser(host + "/backend/user", setUserIsLoaded, setUser, setUserError)}} user={user} setUser={setUser} watchlistItems={watchlistItems} 
                  getHistory={()=>{getHistory(host + "/backend/history", setMoreItemsLoaded, setMoreItems, setMoreItemsError)}}
                  getWatchlist={()=>{getWatchlist(host + "/backend/watchlist", setWatchlistLoaded, setWatchlistItems, setWatchlistError)}}
                  setWatchlistItems={setWatchlistItems}
                  host={host}
        />
        <Login getUser={() => {getUser(host + "/backend/user", setUserIsLoaded, setUser, setUserError)}} user={user} setUser={setUser} host={host}/>
        <Register getUser={() => {getUser(host + "/backend/user", setUserIsLoaded, setUser, setUserError)}} user={user} setUser={setUser} host={host}/>
        <Header host={host} getHistory={()=>{getHistory(host + "/backend/history", setMoreItemsLoaded, setMoreItems, setMoreItemsError)}}/>
        <Routes>
          <Route path="/" element={
            <Layout getUser={() => {getUser(host + "/backend/user", setUserIsLoaded, setUser, setUserError)}} 
                    user={user}
                    setUser={setUser}
                    host={host}
                    watchlistItems={watchlistItems}
                    watchlistError={watchlistError}
                    watchlistIsLoaded={watchlistIsLoaded}
                    error={error}
                    setGlobalError={setGlobalError}
                    getWatchlist={()=>{getWatchlist(host + "/backend/watchlist", setWatchlistLoaded, setWatchlistItems, setWatchlistError)}}
                    clearGlobalError={clearGlobalError}
            />
            }>
            <Route index element={
              <Suspense fallback={(<div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>)}>
                      <Index />
              </Suspense>
            } />
            <Route path="/dashboard" element={
             <Suspense fallback={(<div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>)}>
                <TabDashboard />
              </Suspense>
            } />
            <Route path="/profile" element={
              <Suspense fallback={(<div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>)}>
                    <Profile />
              </Suspense>
            } />
            <Route path="/index/:symbol" element={
              <Suspense fallback={(<div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>)}>
              <IndexView />
              </Suspense>
            } />
            <Route path="/stock/:symbol" element={
              <Suspense fallback={(<div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>)}>
                <StockView />
              </Suspense>
            } />
            <Route path="/accounts" element={
              <Suspense fallback={(<div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>)}>
                <TabAccounts />
              </Suspense>
            } />
            <Route path="/accounts/:account" element={
              <Suspense fallback={(<div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>)}>
                <TransactionsAccountDetail />
              </Suspense>
            } />
            <Route path="/investments/holdings" element={
              <Suspense fallback={(<div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>)}>
                <InvestmentHoldingsView />
              </Suspense>
            } />
            <Route path="/investments/accounts/:account" element={
              <Suspense fallback={(<div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>)}>
                <InvestmentAccountsHoldingsView />
              </Suspense>
            } />
            <Route path="/categories" element={
              <Suspense fallback={(<div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>)}>
                <TabCategories />
              </Suspense>
            } />
            <Route path="/categories/:category" element={
                <Suspense fallback={(<div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>)}>
                  <TransactionsCategoryDetail />
                </Suspense>
              } />
            <Route path="/transactions" element={<Suspense fallback={(<div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>)}><TabTransactions/></Suspense>} />
            <Route path="/budget" element={<Suspense fallback={(<div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>)}><TabBudget /></Suspense>} />
            <Route path="/budget/:category" element={<Suspense fallback={(<div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>)}><BudgetDetail /></Suspense>} />
            <Route path="/savingsGoal" element={<Suspense fallback={(<div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>)}><Goals /></Suspense>} />
            <Route path="/privacyPolicy" element={<Suspense fallback={(<div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>)}><PrivacyPolicy/></Suspense>}/>
            <Route path="/terms" element={<Suspense fallback={(<div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>)}><Terms/></Suspense>}/>
            <Route path="/dataUsePolicy" element={<Suspense fallback={(<div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>)}><DataUse/></Suspense>}/>
            <Route path="/disclaimer" element={<Suspense fallback={(<div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>)}><Disclaimer/></Suspense>}/>
            <Route path="*" element={<Suspense fallback={(<div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>)}><NoPage /></Suspense>} />
          </Route>
        </Routes>
        <Footer setGlobalError={setGlobalError} 
                moreItems={moreItems}
                watchlistItems={watchlistItems}
                itemsLoaded={moreItemsLoaded}
                itemsError={moreItemsError}
                getHistory={()=>{getHistory(host + "/backend/history", setMoreItemsLoaded, setMoreItems, setMoreItemsError)}}
                getWatchlist={()=>{getWatchlist(host + "/backend/watchlist", setWatchlistLoaded, setWatchlistItems, setWatchlistError)}}
                host={host}
                />
    </>
  );
}

export default App;
